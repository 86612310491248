import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from 'vue-slide-toggle';

//sections


export default {
    name: "order",
    components: {
        VueSlideToggle
    },
    data() {
        return {}
    },

    created() {

    },
    computed: {
        ...mapGetters({
            isShowRetailColumn: 'profile/isShowRetailPrice',
            basket: 'basket/basket',
        }),
        totalCountPrice() {
            return this.basket.reduce((prev, cur) => prev + (cur.select_type.price * cur.quantity), 0)
        },
        totalCountRetailPrice() {
            if (this.isShowRetailColumn) {
                return this.basket.reduce((prev, cur) => prev + (cur.select_type.defaultPrice * cur.quantity), 0)
            }
            return 0
        },
    },
    methods: {
        ...mapMutations({
            removeItemFromBasket: 'basket/REMOVE_ITEM_FROM_BASKET'
        }),
        ...mapActions({
            basketProductAction: 'basket/BASKET_PRODUCT_ACTION'
        }),
        deleteItemFromBasket(product) {
            this.$emit('remove-product', product.id)
            this.removeItemFromBasket(product)
            this.basketProductAction({type: 'delete', product_id: product.id}).then(() => {
            })
        }
    }
}
