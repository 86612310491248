import {mapActions, mapGetters, mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue';
import {VueSlideToggle} from 'vue-slide-toggle';
import mainButton from '@/components/main-button/index.vue'
import greenhouse from './greenhouse/index.vue'
import autocomplete from '@/components/autocomplete/index.vue'
import order from '@/modules/profile/section/catalog/order/index.vue'
import ordering from '@/modules/profile/section/catalog/ordering/index.vue'
import products from '@/modules/profile/section/catalog/products/index.vue'
import smallLoader from '@/components/small-loader/index.vue'

//sections


export default {
    name: "catalog",
    components: {
        mainButton,
        VueSlideToggle,
        mainSelect,
        greenhouse,
        autocomplete,
        order,
        ordering,
        products,
        smallLoader
    },
    data() {
        return {
            selectAll: true,
            selectGreenhouse: false,
            //expanded: false,
            openProductGroupsIds: [],
            openMobileProductMenuIds: [],
            productGroupRequestFlagID: 0
        }
    },
    created() {
        this.fetchCatalog()
        this.fetchBasket()
    },
    computed: {
        ...mapGetters({
            profileRequestsFlag: 'profile/isLoadingProfile',
            basket: 'basket/basket',
            getBasketCount: 'basket/basketCount',
            catalogProductGroups: 'profile/catalogGroups',
            isShowRetailColumn: 'profile/isShowRetailPrice',
            productGroups: 'profile/productGroups'
        })
    },
    methods: {
        ...mapMutations({
            incrementBasketListCount: 'basket/INCREMENT_BASKET_LIST_COUNT',
            decrementBasketListCount: 'basket/DECREMENT_BASKET_LIST_COUNT',
            removeItemFromBasket: 'basket/REMOVE_ITEM_FROM_BASKET',
        }),
        ...mapActions({
            fetchCatalog: 'profile/GET_CATALOG',
            fetchGreenhouse: 'profile/GET_GREENHOUSE',
            fetchBasket: 'basket/GET_BASKET',
            basketCount: 'basket/BASKET_PRODUCT_ACTION',
            fetchProductGroup: 'profile/GET_PRODUCT_GROUP'
        }),
        selectTab(el) {
            switch (el) {
                case 'all': {
                    this.fetchCatalog().then(() => {
                        this.selectAll = true;
                        this.selectGreenhouse = false;
                    })
                    break;
                }
                case 'green': {
                    this.fetchGreenhouse().then(() => {
                        this.selectAll = false;
                        this.selectGreenhouse = true;
                    })
                    break
                }
            }
        },
        onOpenProductGroupMenu(productGroup) {
            if (this.openProductGroupsIds.includes(productGroup.id)) {
                const checkProductGroupChildren = this.productGroups.find(el => el.slug === productGroup.slug)
                checkProductGroupChildren.productGroups.data.forEach(el => {
                    this.openProductGroupsIds = this.openProductGroupsIds.filter(id => id !== el.id)
                })
                this.openProductGroupsIds = this.openProductGroupsIds.filter(id => id !== productGroup.id)
            } else {
                const checkIncludingProductGroup = this.productGroups.find(el => el.id === productGroup.id)
                if (!checkIncludingProductGroup) {
                    this.productGroupRequestFlagID = productGroup.id
                    this.fetchProductGroup(productGroup.slug).then(() => {
                        this.openProductGroupsIds.push(productGroup.id)
                        this.productGroupRequestFlagID = 0
                    })
                } else {
                    this.openProductGroupsIds.push(productGroup.id)
                }
            }
        },
        resetProductCount(prodId) {
            this.productGroups.forEach(prods => prods.products.data
                .forEach(prod => prod.id === prodId ? prod.count = 1 : prod.count))
        },
        productGroupsFound(productGroupID) {
            return this.productGroups.find(prod => prod.id === productGroupID)
        }
    }
}
