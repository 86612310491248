import {mapActions, mapGetters, mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue';
import {VueSlideToggle} from 'vue-slide-toggle';
import mainButton from '@/components/main-button/index.vue'
import autocomplete from '@/components/autocomplete/index.vue'
import {minLength, required} from "vuelidate/lib/validators";
import smallLoader from '@/components/small-loader/index.vue'
import {loginByPhone} from "@/mixins/loginByPhone";

//sections


export default {
    name: "ordering",
    components: {
        mainButton,
        VueSlideToggle,
        mainSelect,
        autocomplete,
        smallLoader
    },
    data() {
        return {
            ordering1: false,
            ordering2: false,
            ordering3: false,
            payload: {
                logistik: '',
                payment: '',
                type: '',
                legalPayment: ''
            },
            town: '',
            otherRecipient: false,
            recipientName: '',
            recipientSurName: '',
            recipientPhone: '',
            comment: '',
            deliveryRequestFlag: false,
            paymentRequestFlag: false,
            selectedWarRef: '',
            selectedCityRef: '',
            selectedAddressRef: '',
            courier: {
                id: 0,
                label: this.$t('personalOffice.orders.department'),
            },
            courierAddress: {
                city: '',
                address: '',
                home: ''
            },
            npDeliveryType: [
                {
                    id: 0,
                    label: this.$t('personalOffice.orders.department'),
                },
                {
                    id: 1,
                    label: this.$t('personalOffice.orders.courier'),
                }
            ],
            npDelivery: {
                city: '',
                department: ''
            },
            satDelivery: {
                city: '',
                department: ''
            },
            delivery: {
                city: '',
                cityId: '',
                regionId: '',
                department: ''
            },
            departmentsList: [],
            paymentType: '',
            errorPaymentType: false,
            selectedOrganizationType: '',
            ipn: '',
            edrpo: '',
            payment: '',
            filialEdrpo: '',
            filialName: ''
        }
    },
    validations: {
        recipientName: {
            required,
            minLength: minLength(2)
        },
        recipientSurName: {
            required,
            minLength: minLength(2)
        },
        recipientPhone: {
            required,
            minLength: minLength(19),
        },
        town: {required},
        payload: {
            logistik: {required},
            payment: {required},
        },
        npDelivery: {
            city: {required},
            department: {required}
        },
        selectedCityRef: {required},
        selectedAddressRef: {required},
        courierAddress: {
            home: {required}
        },
        satDelivery: {
            city: {required},
            department: {required}
        },
        delivery: {
            city: {required},
            department: {required},
        },
        userInfoPayload: {
            name: {required},
            lastName: {required}
        },
        userContactsPayload: {
            phone: {required},
            email: {required}
        },
    },
    watch: {
        'payload.logistik': function () {
            this.courierAddress.city = ''
            this.courierAddress.yaddress = ''
            this.courierAddress.home = ''
        },
        payment() {
            if (this.ipnData.pdvStatus) {
                if (this.ipn.length === 10 && this.payment && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment(
                        {
                            checked: true,
                            data: this.paymentsData
                        }
                    )
                }
                if (this.edrpo.length === 8 && this.payment && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            } else {
                if (this.ipn.length === 10 && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
                if (this.edrpo.length === 8 && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }

        },
        selectedOrganizationType() {
            this.selectPayment({checked: false})
            this.resetData()
            this.ipn = ''
            this.edrpo = ''
            this.payment = ''
            this.filialEdrpo = ''
            this.filialName = ''
        },
        filialEdrpo() {
            if (this.ipnData.pdvStatus) {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1 && this.payment) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            } else {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }

        },
        filialName() {
            if (this.ipnData.pdvStatus) {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1 && this.payment) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            } else {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }
        },
        edrpo() {
            if (this.edrpo.length === 8) {
                this.sendIpn({code: this.edrpo}).then(() => {
                    this.$toasted.success(this.$t('personalOffice.organization.success'));
                    if (this.ipnData.pdvStatus) {
                        if (this.payment) {
                            if (this.selectedOrganizationType.id === 4) {
                                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                                    this.selectPayment({
                                        checked: true,
                                        data: this.paymentsData
                                    })
                                }
                            } else {
                                this.selectPayment({
                                    checked: true,
                                    data: this.paymentsData
                                })
                            }

                        }
                    } else {
                        this.selectPayment({
                            checked: true,
                            data: this.paymentsData
                        })
                    }

                }).catch(() => {
                    this.$toasted.error(this.$t('personalOffice.organization.error'));
                })
            }
        },
        ipn() {
            if (this.ipn.length === 10) {
                this.sendIpn({code: this.ipn}).then(() => {
                    this.$toasted.success(this.$t('personalOffice.ipn.success'));
                    if (this.ipnData.pdvStatus) {
                        if (this.payment) {
                            if (this.selectedOrganizationType.id === 4) {
                                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                                    this.selectPayment({
                                        checked: true,
                                        data: this.paymentsData
                                    })
                                }
                            } else {
                                this.selectPayment({
                                    checked: true,
                                    data: this.paymentsData
                                })
                            }
                        }
                    } else {
                        this.selectPayment({
                            checked: true,
                            data: this.paymentsData
                        })
                    }
                }).catch(() => {
                    this.$toasted.error(this.$t('personalOffice.ipn.error'));
                })
            }
        },
        paymentType() {
            if (this.paymentType.id !== 5) {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
            if (this.paymentType.id === 5) {
                this.selectPayment({checked: false})
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }
        }
    },
    created() {
        this.onRequestTown()
        if (!this.organizationTypeList.length) {
            this.getOrganizationType()
        }
    },
    mounted() {
        this.resetData()
        this.$root.$on('sendDeliveryData', () => {
        })
        this.$root.$on('sendPaymentData', () => {
            this.sendData()
        })
    },
    computed: {
        ...mapGetters({
            deliveriesList: 'order/deliveriesList',
            paymentsList: 'order/paymentsList',
            userInfoPayload: 'profile/userInfo',
            userContactsPayload: 'profile/userContacts',
            orderLoading: 'order/orderLoading',
            citiesList: 'order/citiesList',
            departmentList: 'order/departmentsList',
            deliveryList: 'order/deliveriesList',
            basketCount: 'basket/basketCount',
            basket: 'basket/basket',
            orderData: 'order/orderData',
            addressesList: 'order/addressesList',
            npTownsLoading: 'order/npTownsLoading',
            npDepartmentsLoading: 'order/npDepartmentsLoading',
            addressListLoading: 'order/addressListLoading',
            paymentList: 'order/paymentsList',
            organizationTypeList: 'order/calculationTypeList',
            setIpnLoading: 'order/setIpnLoading',
            paymentDetails: 'order/selectPayment',
            ipnData: 'order/setIpn'
        }),
        townErrors() {
            let error = [];
            if (!this.$v.town.$dirty) {
                return error;
            } else if (!this.$v.town.required) {
                error.push(this.$t('validation.required'))
            }
            return error;
        },
        courierAddressCityErrors() {
            let error = [];
            if (!this.$v.selectedCityRef.$dirty) {
                return error;
            } else if (!this.$v.selectedCityRef.required) {
                error.push(this.$t('personalOffice.orders.cityError'))
            }
            return error;
        },
        courierAddressStreetErrors() {
            let error = [];
            if (!this.$v.selectedAddressRef.$dirty) {
                return error;
            } else if (!this.$v.selectedAddressRef.required) {
                error.push(this.$t('personalOffice.orders.streetError'))
            }
            return error;
        },
        courierAddressHomeErrors() {
            let error = [];
            if (!this.$v.courierAddress.home.$dirty) {
                return error;
            } else if (!this.$v.courierAddress.home.required) {
                error.push(this.$t('validation.required'))
            }
            return error;
        },
        recipientSurNameErrors() {
            let error = [];
            if (!this.$v.recipientSurName.$dirty) {
                return error;
            } else if (!this.$v.recipientSurName.required) {
                error.push(this.$t('validation.required'))
            } else if (!this.$v.recipientSurName.minLength) {
                error.push(this.$t('validation.minLength', {'size': 2}))
            }
            return error;
        },
        recipientPhoneErrors() {
            let error = [];
            if (!this.$v.recipientPhone.$dirty) {
                return error;
            } else if (!this.$v.recipientPhone.required) {
                error.push(this.$t('validation.required'))
            } else if (!this.$v.recipientPhone.minLength) {
                error.push(this.$t('validation.minLength', {'size': 19}));
            }
            return error;
        },
        logistikErrors() {
            let error = [];
            if (!this.$v.payload.logistik.$dirty) {
                return error;
            } else if (!this.$v.payload.logistik.required) {
                error.push(this.$t('personalOffice.orders.selectDeliveryMethod'))
            }
            return error;
        },
        paymentErrors() {
            let error = [];
            if (!this.$v.payload.payment.$dirty) {
                return error;
            } else if (!this.$v.payload.payment.required) {
                error.push(this.$t('personalOffice.orders.selectPaymentMethod'))
            }
            return error;
        },
        recipientNameErrors() {
            let error = [];
            if (!this.$v.recipientName.$dirty) {
                return error;
            } else if (!this.$v.recipientName.required) {
                error.push(this.$t('validation.required'))
            } else if (!this.$v.recipientName.minLength) {
                error.push(this.$t('validation.minLength', {'size': 2}))
            }
            return error;
        },
        npDeliveryErrors() {
            let error = [{city: '', department: ''}];
            if (!this.$v.npDelivery.$dirty) {
                return error;
            } else if (!this.$v.npDelivery.city.required) {
                error[0].city = this.$t('validation.required')
            } else if (!this.$v.npDelivery.department.required) {
                error[0].department = this.$t('validation.required')
            }
            return error;
        },
        satDeliveryErrors() {
            let error = [{city: '', department: ''}];
            if (!this.$v.satDelivery.$dirty) {
                return error;
            } else if (!this.$v.satDelivery.city.required) {
                error[0].city = this.$t('validation.required')
            } else if (!this.$v.satDelivery.department.required) {
                error[0].department = this.$t('validation.required')
            }
            return error;
        },
        deliveryErrors() {
            let error = [{city: '', department: ''}];
            if (!this.$v.delivery.$dirty) {
                return error;
            } else if (!this.$v.delivery.city.required) {
                error[0].city = this.$t('validation.required')
            } else if (!this.$v.delivery.department.required) {
                error[0].department = this.$t('validation.required')
            }
            return error;
        },
        userInfoErrors() {
            let error = [];
            if (!this.$v.userInfoPayload.$dirty || !this.$v.userContactsPayload.$dirty) {
                return error;
            } else if (
                !this.$v.userInfoPayload.name.required ||
                !this.$v.userInfoPayload.lastName.required ||
                !this.$v.userContactsPayload.email.required ||
                !this.$v.userContactsPayload.phone.required
            ) {
                error.push(this.$t('personalOffice.orders.fillPersonalInfo'))
            }
            return error;
        },
        paymentsData() {
            let obj = {}
            obj.ownership_type_id = this.selectedOrganizationType.id
            obj.payment_id = this.paymentType.id
            obj.payment_info = {
                inn: this.ipn,
                edrpo: this.edrpo,
                organisation_name: this.ipnData.fullName ? this.ipnData.fullName : '',
                pdv_status: this.ipnData.pdvStatus ? this.ipnData.pdvStatus : false,
                document_nds_status: this.payment === 'WithVAT',
                filial_code: this.filialEdrpo,
                filial_name: this.filialName
            }
            return obj
        }
    },
    methods: {
        ...mapMutations({
            setRecipientData: 'order/SET_DATA_OTHER_RECIPIENT',
            setDepartments: 'order/NOVA_POSHTA_SET_DEPARTMENS',
            setDeliveries: 'order/SELECT_DELIVERY',
            resetBasket: 'basket/RESET_BASKET',
            resetProductGroups: 'profile/RESET_PRODUCT_GROUPS',
            closeSuccessPopup: 'order/SHOW_SUCCESS_POPUP',
            resetTownsList: 'order/SET_TOWNS_LIST',
            resetData: 'order/RESET_IPN',
            selectPayment: 'order/SELECT_PAYMENT'
        }),
        ...mapActions({
            fetchDepartments: 'order/NOVA_POSHTA_GET_DEPARTMENS',
            getDeliveryList: 'order/GET_DELIVERIES_LIST',
            sendOrder: 'order/SEND_ORDER',
            sendBasket: 'basket/UPDATE_BASKET',
            fetchDeliveriesList: 'order/GET_DELIVERIES_LIST',
            fetchPaymentsType: 'order/GET_PAYMENTS_TYPE',
            getTownsList: 'order/GET_TOWNS_LIST',
            getAddressesList: 'order/GET_ADDRESS_LIST',
            getDepartmentsList: 'order/GET_DEPARTMENTS_LIST',
            getOrganizationType: 'order/CALCULATION_TYPE',
            sendIpn: 'order/SEND_IPN'
        }),
        toggleCardState(num) {
            switch (num) {
                case 1: {
                    if (this.deliveriesList.length) {
                        this.switchDeliveryMenu()
                    } else {
                        this.deliveryRequestFlag = true
                        this.fetchDeliveriesList().then(() => {
                            this.deliveryRequestFlag = false
                            this.switchDeliveryMenu()
                        })
                    }
                    break;
                }
                case 2: {
                    if (!this.checkingDeliveryPayload()) return
                    if (this.paymentsList.length) {
                        this.switchPaymentMenu()
                    } else {
                        this.paymentRequestFlag = true
                        this.fetchPaymentsType().then(() => {
                            this.paymentRequestFlag = false
                            this.switchPaymentMenu()
                        })
                    }
                    break;
                }
                case 3: {
                    if (!this.checkingDeliveryPayload()) return
                    this.$v.payload.payment.$touch()
                    if (this.$v.payload.payment.$anyError) return

                    this.ordering3 = !this.ordering3;
                    this.ordering1 = false;
                    this.ordering2 = false;
                    break;
                }
            }
        },
        onRequestTown(data, service) {
            this.selectedCityRef = ''
            this.resetTownsList([])
            if (data?.length >= 3) {
                this.getTownsList({
                    'city_name': data,
                    'service': service
                })
            }
        },
        requestTown(data, service) {
            if (data.length >= 3) {
                this.getTownsList({
                    'city_name': data,
                    'service': service
                })
            }
        },
        requestAddresses(type) {
            this.selectedAddressRef = ''
            if (this.courierAddress.address.length >= 3) {
                this.getAddressesList({
                    'city_name': this.courierAddress.city,
                    'service': type,
                    'city_ref': this.selectedCityRef,
                    'order': this.basketCount.basket.id,
                    'street_name': this.courierAddress.address
                })
            }
        },
        findDelCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description === this.npDelivery.city)
                if (city) {
                    this.selectedCityRef = city.ref
                    this.getDepartmentsList({
                        'city_name': this.npDelivery.city,
                        'service': 'nova_poshta',
                        'city_ref': this.selectedCityRef,
                        'order': this.basketCount.basket.id
                    })
                }

            }, 500)

        },
        findCatCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description === this.satDelivery.city)
                if (city) {
                    this.selectedCityRef = city.ref
                    this.getDepartmentsList({
                        'city_name': this.satDelivery.city,
                        'service': 'cat',
                        'city_ref': this.selectedCityRef,
                        'order': this.basketCount.basket.id
                    })
                }

            }, 500)

        },
        findDeliveryCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.name === this.delivery.city)

                if (city) {
                    this.selectedCityRef = city.id
                    this.getDepartmentsList({
                        'city_name': this.delivery.city,
                        'service': 'delivery',
                        'city_ref': this.selectedCityRef,
                        'order': this.basketCount.basket.id
                    })
                }

            }, 500)

        },
        findRefCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description ? el.description : el.name === this.courierAddress.city)
                if (city) {
                    city.ref ? this.selectedCityRef = city.ref : this.selectedCityRef = '63422722-4172-11dd-9198-001d60451983'
                    this.getDepartmentsList({
                        'city_name': this.courierAddress.city,
                        'service': 'nova_poshta',
                        'city_ref': this.selectedCityRef,
                        'order': this.basketCount.basket.id
                    })
                }

            }, 500)

        },
        findRefStreet() {
            setTimeout(() => {
                let street = this.addressesList.find(el => el.description === this.courierAddress.address)
                if (street) {
                    street.ref ? this.selectedAddressRef = street.ref : this.selectedAddressRef = '63422722-4172-11dd-9198-001d60451983'
                }

            }, 500)
        },
        requestDepartments() {
            this.getDepartmentsList({
                'city_name': this.npDelivery.city,
                'service': 'nova_poshta',
                'city_ref': this.selectedCityRef,
                'order': this.basketCount.basket.id
            })
        },
        requestCatDepartments() {
            this.getDepartmentsList({
                'city_name': this.satDelivery.city,
                'service': 'cat',
                'city_ref': this.selectedCityRef,
                'order': this.basketCount.basket.id
            })
        },
        requestDeliveryDepartments() {
            this.getDepartmentsList({
                'city_name': this.delivery.city,
                'service': 'delivery',
                'city_ref': this.selectedCityRef,
                'order': this.basketCount.basket.id
            })
        },
        submitOrder() {
            if (!this.checkingDeliveryPayload()) return
            this.$v.payload.payment.$touch()
            if (this.$v.payload.payment.$anyError) return

            let obj = this.basketCount.basket
            obj.delivery_details = {}
            switch (this.payload.logistik) {
                case 'nova_poshta': {
                    obj.delivery_details.delivery_id = 4
                    obj.delivery_details.number = this.npDelivery.department
                    obj.delivery_details.self_pickup = false
                    obj.delivery_details.city_ref = this.selectedCityRef
                    if (this.courier.id === 1) {
                        obj.delivery_details.city = this.courierAddress.city
                        obj.delivery_details.street_name = this.courierAddress.address
                        obj.delivery_details.street_ref = this.selectedAddressRef
                        obj.delivery_details.building_number = this.courierAddress.home
                        obj.delivery_details.to_flat = true
                    } else {
                        obj.delivery_details.city = this.npDelivery.city
                        let warehouses = this.departmentList.find(el => el.description === this.npDelivery.department)
                        obj.delivery_details.warehouse_ref = warehouses.ref
                        obj.delivery_details.to_flat = false
                    }
                    break
                }
                case 'cat': {
                    obj.delivery_details.delivery_id = 2
                    obj.delivery_details.city_ref = this.selectedCityRef
                    obj.delivery_details.number = this.satDelivery.department
                    obj.delivery_details.self_pickup = false
                    if (this.courier.id === 1) {
                        obj.delivery_details.city = this.courierAddress.city
                        obj.delivery_details.street_name = this.courierAddress.address
                        obj.delivery_details.street_ref = this.selectedAddressRef
                        obj.delivery_details.building_number = this.courierAddress.home
                        obj.delivery_details.to_flat = true
                    } else {
                        obj.delivery_details.city = this.satDelivery.city
                        let warehouses = this.departmentList.find(el => el.description === this.satDelivery.department)
                        obj.delivery_details.warehouse_ref = warehouses.ref
                        obj.delivery_details.to_flat = false
                    }
                    break
                }
                case 'delivery': {
                    obj.delivery_details.delivery_id = 3
                    obj.delivery_details.city_ref = this.selectedCityRef
                    obj.delivery_details.number = this.delivery.department
                    obj.delivery_details.self_pickup = false
                    if (this.courier.id === 1) {
                        obj.delivery_details.city = this.courierAddress.city
                        obj.delivery_details.street_name = this.courierAddress.address
                        obj.delivery_details.street_ref = this.selectedAddressRef
                        obj.delivery_details.building_number = this.courierAddress.home
                        obj.delivery_details.to_flat = true
                    } else {
                        obj.delivery_details.city = this.delivery.city
                        let warehouses = this.departmentList.find(el => el.description === this.delivery.department)
                        obj.delivery_details.warehouse_ref = warehouses.ref
                        obj.delivery_details.to_flat = false
                    }
                    break
                }
                case 'self_pickup': {
                    obj.delivery_details.delivery_id = 1
                    obj.delivery_details.self_pickup = true
                    break
                }
            }

            this.sendData()
            const selectedPayment = this.paymentsList.find(el => el.key === this.payload.payment)
            obj.payment_details = {
                payment_id: selectedPayment.id,
                ownership_type_id: this.paymentDetails.data.ownership_type_id,
                payment_info: {
                   pdv_status: this.ipnData.pdvStatus ? this.ipnData.pdvStatus : false,
                   document_nds_status: this.payment === 'WithVAT' ? 1 : 0
                }
            }
            if (this.paymentDetails.data.payment_info.document_nds_status) {
                obj.payment_details.payment_info.document_nds_status = this.paymentDetails.data.payment_info.document_nds_status
            }
            if (this.paymentDetails.data.payment_info.edrpo) {
                obj.payment_details.payment_info.edrpo = this.paymentDetails.data.payment_info.edrpo
            }
            if (this.paymentDetails.data.payment_info.filial_code) {
                obj.payment_details.payment_info.filial_code = this.paymentDetails.data.payment_info.filial_code
            }
            if (this.paymentDetails.data.payment_info.filial_name) {
                obj.payment_details.payment_info.filial_name = this.paymentDetails.data.payment_info.filial_name
            }
            if (this.paymentDetails.data.payment_info.inn) {
                obj.payment_details.payment_info.inn = this.paymentDetails.data.payment_info.inn
            }
            if (this.paymentDetails.data.payment_info.organisation_name) {
                obj.payment_details.payment_info.organisation_name = this.paymentDetails.data.payment_info.organisation_name
            }
            if (this.paymentDetails.data.payment_info.pdv_status) {
                obj.payment_details.payment_info.pdv_status = this.paymentDetails.data.payment_info.pdv_status
            }

         //  const selectedPayment = this.paymentsList.find(el => el.key === this.payload.payment)
            // obj.payment_details = {
            //     payment_id: selectedPayment.id,
            //     ownership_type_id: this.paymentDetails.data.ownership_type_id,
            //     payment_info: {
            //         pdv_status: this.ipnData.pdvStatus ? this.ipnData.pdvStatus : false,
            //         document_nds_status: this.payment === 'WithVAT',
            //     }
            // }
            // if (this.ipn && this.organisation_name) {
            //     obj.payment_details.payment_info.inn = this.ipn
            //     obj.payment_details.payment_info.organisation_name = this.ipnData.fullName ? this.ipnData.fullName : ''
            // }
            // if (this.edrpo && this.filialEdrpo && this.filialName) {
            //     obj.payment_details.payment_info.edrpo = this.edrpo
            //     obj.payment_details.payment_info.filial_code = this.filialEdrpo
            //     obj.payment_details.payment_info.filial_name = this.filialName
            // }
            //
            // obj.ownership_type_id = this.selectedOrganizationType.id
            // obj.payment_id = selectedPayment.id

            obj.delivery_details.another_recipient = this.otherRecipient
            if (this.otherRecipient) {
                obj.delivery_details.middle_name = this.recipientSurName
                obj.delivery_details.name = this.recipientName
                obj.delivery_details.phone = this.recipientPhone
            }

            obj.order_id = this.basket.basketId

            obj.user_info = {}
            obj.user_info.name = this.userInfoPayload.name
            obj.user_info.middle_name = this.userInfoPayload.lastName
            obj.user_info.city = this.town
            obj.user_info.phone = this.userContactsPayload.phone
            obj.user_info.email = this.userContactsPayload.email

            obj.services_comment = this.comment

            this.$v.userInfoPayload.$touch()
            this.$v.userContactsPayload.$touch()
            if (this.$v.userInfoPayload.$anyError) return
            if (this.$v.userContactsPayload.$anyError) return

            this.sendOrder(obj).then(() => {
                this.resetBasket()
                this.sendBasket([])
                this.resetProductGroups()
                this.closeSuccessPopup(true)
                document.getElementById('liqpay-form').submit()
            })
        },
        checkingDeliveryPayload() {
            this.$v.town.$touch()
            this.$v.payload.logistik.$touch()
            if (this.otherRecipient) {
                this.$v.recipientName.$touch()
                this.$v.recipientSurName.$touch()
                this.$v.recipientPhone.$touch()
                if (this.$v.recipientName.$anyError) return
                if (this.$v.recipientSurName.$anyError) return
                if (this.$v.recipientPhone.$anyError) return
            }

            if (this.$v.town.$anyError) return
            if (this.$v.payload.logistik.$anyError) return

            if (this.courier.id === 1) {
                this.$v.selectedCityRef.$touch()
                this.$v.selectedAddressRef.$touch()
                this.$v.courierAddress.home.$touch()
                if (this.$v.selectedCityRef.$anyError) return
                if (this.$v.selectedAddressRef.$anyError) return
                if (this.$v.courierAddress.home.$anyError) return
            } else {
                switch (this.payload.logistik) {
                    case 'nova_poshta': {
                        this.$v.npDelivery.$touch()
                        if (this.$v.npDelivery.$anyError) return
                        break
                    }
                    case 'cat': {
                        this.$v.satDelivery.$touch()
                        if (this.$v.satDelivery.$anyError) return
                        break
                    }
                    case 'delivery': {
                        this.$v.delivery.$touch()
                        if (this.$v.delivery.$anyError) return
                        break
                    }
                }
            }
            return 'success'
        },
        switchDeliveryMenu() {
            this.ordering1 = !this.ordering1;
            this.ordering2 = false;
            this.ordering3 = false;
        },
        switchPaymentMenu() {
            this.ordering2 = !this.ordering2;
            this.ordering1 = false;
            this.ordering3 = false;
        },
        sendData() {
            if (this.selectedOrganizationType && this.payment && this.payload.payment === 'cashless_payment') {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
            if (this.payload.payment === 'in_cash') {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
            if (this.payload.payment === 'card_on_site') {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
        },
    }
}
