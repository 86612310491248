import {mapMutations} from "vuex";
import  mainSelect from '@/components/main-select/index.vue';
import {VueSlideToggle} from 'vue-slide-toggle';
import mainButton from '@/components/main-button/index.vue'
//sections


export default {
  name: "greenhouse",
  components: {
    mainButton,
    VueSlideToggle,
    mainSelect
  },
  data() {
    return {
      type: [{id: 0, title: 'Тип організації'}, {id: 1, title: '1'}, {id: 2, title: '2'}],
      selectAll: true, 
      selectGreenhouse: false,
      expanded: false,
      expanded2: false,
      ordering1: false,
      ordering2: false,
      ordering3: false,
      payload: {
        logistik: '',
        payment: '',
        type: '',
        legalPayment: '',
      },
    }
  },

  created() {
  },
  computed: {

  },

  methods: {
    ...mapMutations({}), 
    selectTab() {

    },
    toggleItemState(num) {
      switch(num) {
        case 1: {
          this.expanded = !this.expanded;
          break;
        }
        case 2: {
          this.expanded2 = !this.expanded2;
          break;
        }
      }
    },
    toggleCardState(num) {
      switch(num) {
        case 1: {
          this.ordering1 = !this.ordering1;
          break;
        }
        case 2: {
          this.ordering2 = !this.ordering2;
          break;
        }
        case 3: {
          this.ordering3 = !this.ordering3;
          break;
        }
      }
    },
  }
}
